* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Roboto Serif", serif;
}
.flex {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-start {
  display: -webkit-flex;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.flex-start h3 {
  margin-left: 10px;
}
.center {
  text-align: center;
}
#open {
  right: 20px;
}
i {
  margin-bottom: 10px;
}
body {
  /*margin: 50px;*/
  color: white;
  position: relative;
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

main {
  min-height: 100vh;
}
/* Track */
::-webkit-scrollbar-track {
  background: #FFD700;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #00C7FF; 
  border-radius: 20px;
}
/*---Navigation--*/
.nav {
  /* background-color: rgba(255, 150, 100); */
  background-color: #333333;
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 20%;
}
.nav * {
  margin: 10px 10px;
  position: relative;
  top: 25%;
 }
 .nav div a, #geegstack {
  color: white; 
  }

#vertical-line {
  border: 1px solid white;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 19.8%;
}
.home, .contact, .about-me, .portfolio {
  transition: all 1s;
}
  .hovered{
    transform: translateX(25px);
  }
.font {
  font-size: 20px;
}


/*---------------main-------------*/
main {
  margin-left: 11%;
  position: absolute;
  top : 0px;
  left: 9%;
  right: 0%;
  padding: 40px;
}
a, li {
  list-style-type: none;
  text-decoration: none;
}

/*----Header--*/
.theme-img {
  width: 3%;
 animation: spin 10s linear infinite;
 cursor: pointer;
 position: relative;
 position: fixed;
 right: 25px;
 top: 5%;
 z-index: 2;
}
.theme-div {
  display: flex;
  justify-content: space-around;
  background-color: white;
  position: fixed;
  right: -200px;
  top: 12%;
  width: 150px;
  height: 50px;
  padding: 10px 10px;
  border-radius: 10px;
  transition: 1s;
  z-index: 2;
}
#theme-img2 {
font-size: 0rem;
}
#theme-img {
  font-size: 0rem;
}
.theme-div * {
  margin: 5px;
  cursor: pointer;
}
.color-selecion {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
 .one {
   background-color:#000000;
}
 .two {
   background-color:  #83580b;
}

 .three {
   background-color: cyan;
}
.four {
  background-color: #552e5a;
}
h1 {
  font-weight: 900;
}
 @keyframes spin {
   from {
     transform: rotate(0deg);
   }
   to {
     transform: rotate(360deg);
   }
 }
 
#hr-1 {
  border: 1px solid white;
  margin-top: 5px;
  width: 60%;
}
#hr-2 {
  border: 1px solid white;
  margin-top: 5px;
  width: 30%;
}
    
/******Article-----*/

 #technologies {
  margin-left: 100px;
}
#ul-1 *, #ul-2 * {
margin: 10px 0px;
}
.h3 {
  font-size: 1.4rem;
  font-weight: bold;
}
section {
  margin-top: 50px;
}

@keyframes slideInLeft2 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
#p-about-me {
  max-width: 850px;
  line-height: 1.5rem;
  margin-bottom: 50px;
}
#geegstack {
  text-decoration: underline;
}
#button-container {
  margin: 64px 0px;
}
.button {
  background-color: #155263;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border-style: none;
  margin: 0px 3px;
}

/*--------Home Page---------*/
#home {
  height: 100vh;
}
.home-home { 
  animation: 2s ease-out 0s 1 slideInLeft3;  
}
.home-about-me {
   animation: 2s ease-out 0.5s 1 slideInLeft3;
}

 .home-portfolio { 
  animation: 2s ease-out 1s 1 slideInLeft3;
}

 .home-contact {animation: 2s ease-out 1.5s 1 slideInLeft3;
}


@keyframes slideInLeft3 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

header {
  animation: 0.2s ease 0s 1 slideInLeft;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

#hi {
  margin-bottom: 30px;
  animation: 1.5s ease 0s 1 slideInRight;
}

@keyframes slideInRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

#hi p {
  margin-top: 3px;
}
#p-about-self {
  max-width: 400px;
  line-height: 1.5rem;
  animation: 2s ease 0s 1 slideInLeft2;
}
#social-media-handles {
  margin-top: 10px;
}
#social-media-handles * {
  margin-right: 5px;
  color: white;
  cursor: pointer;
  transition: all 1s;
  animation: 10s ease-out 0s 1 slideInUpward;
}
#social-media-handles *:hover {
transform: translateY(10px);
}
@keyframes slideInUpward {
  0% {
    transform: translateY(100%);
  }
  70% {
    transform: translateY(0);
  }
}

#profile-img {
  width: 40%;
  border-radius: 50%;
  margin-right: 100px;
  /*animation: 10s ease 0s 1 slideInLeft3*/
  }
  .popUp {
    animation: popUp 4s ease forwards;
  }  

@keyframes popUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}


/*--------About Me Page---------*/

#about-me-main section {
  animation: 1s ease 0.3s 1 slideInLeft4;
}
@keyframes slideInLeft4 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
#theme-img {
  width: 3%;
 animation: spin 10s linear infinite;
 cursor: pointer;
 position: fixed;
 right: 10px;
}
h1 {
  font-weight: 900;
}
 @keyframes spin {
   from {
     transform: rotate(0deg);
   }
   to {
     transform: rotate(360deg);
   }
 }
#click-here {
  color: white;
}
#technologies {
  margin: auto 30px;
}
#technologies *{
  margin: 10px 0px;
}
#technologies div h3 {
  margin: 20px 0px;
}
#html-level, #css-level, #tailwind-level, #react-level, #express-level {
  height: 7px;
  width: 450px;
  border-radius: 7px;
  position: relative;
}
#html-percentage, #css-percentage, #tailwind-percentage, #react-percentage, #express-percentage {
  border-radius: 7px;
  height: 5px;
  position: absolute;
  top: -10px;
}
#html-percentage {
  width: 90%;
}
#css-percentage {
  width: 85%;
}
#tailwind-percentage {
  width: 70%;
}
#react-percentage { 
  width: 75%;
}
#express-percentage {
  width: 65%;
}

/*--------Portfolio Page---------*/
.wrap {
  flex-wrap: wrap;
}
article.portfolio {
  padding: auto 40px;
  max-width: 30%;
  overflow: hidden;
  position: relative;
}
article.portfolio:hover {
  transform: translateX(0px)
}
article.portfolio img {
  max-width: 90%;
  min-width: 90%;
  max-height: 50vh;
  min-height: 50vh;
  margin: 20px;
  transition: transform 0.5s;
  cursor: pointer;
  position: relative;
}
.layer {
  background: linear-gradient(rgba(0,0,0,0.6), #ff004f);
  width: 90.5%;
  height: 0vh;
  position: absolute;
  bottom: 10.5%;
  left: 5%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  text-align: center;
  transition: height 0.5s;
}
.layer h3{
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 1.5rem;
  }
.layer p {
  font-size: 0.8rem;
}
.layer a {
  margin-top: 20px;
  color: #ff004f;
  text-decoration: none;
  background-color: white;
  padding: 15px;
  text-align: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
article.portfolio:hover .layer {
  height: 50vh;
}

/*--------Contact Page---------*/
.center h3 {
  font-size: 2rem;
  margin: 10px 0px;
  color: #155263;
}
form {
  width: 100%;
}
.center p {
  font-size: 1.3rem;
}
div.center {
  margin: 50px 0px;
}
.flex-contact {
  flex-direction: column;
  align-items: center;
}
.flex-contact h3 {
  margin-bottom: 10px;
}
.start {
  justify-content: stretch;
  align-items: start;
}
.start * {
  margin: 10px;
}

.label input {
  width: 100%;
  margin: 10px 0px;
  height: 10vh;
  font-size: 1.3rem;
  text-align: center;
  color: white;
  border-radius: 30px;
  border-style: none;
}
#input1, #input2 {
  width: 100%;
}
#message {
  height: 20vh;
  border-style: none;
}
.send {
  padding: 10px 20px;
  color: white;
  border-style: none;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 5px;
}
.nav2 {
  display: none;
}

 #msgDelivered {
  color: white;
 }
 @media screen and (max-width:1280px){
  .layer {
    bottom: 8.3%;
  }
 }
 @media screen and (max-width:1200px) {
 main {
  padding-bottom: 150px;
 }
.theme-div {
  width: 150px;
} 
  .section-flex {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  #profile-img {
    margin: 50px auto; 
    width: 40%;
  }
  #p-about-self {
    max-width: 1400px;
    line-height: 1.5rem;
  }
  #theme-img {
    width: 5%;
  }
  .nav {
    display: none;
  }
  main {
    overflow: hidden;
    position: relative;
    left: -11%;
    width: 100%;
    padding-bottom: -100px;
  }
  #horizontal-line {
    border: 1px solid white;
    width: 100%;
    position: fixed;
    top: 87.5%;
  }
  .nav2 * {
    overflow: hidden;
  }
  .nav2 {
    z-index: 5;
    overflow-y: hidden;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    top: 88%;
    width: 100%;
  }
  .layer {
    bottom: 6%;
  }
  .nav2 div {
    overflow-y: hidden;
    margin: 20px 20px;
    padding: 20px 30px;
    border-radius: 10px;
  }
  .nav2 div a {
    color: white;
  }
  .about-flex {
    flex-direction: column;
  }
  .about-flex div div {
    justify-content: flex-start;
  }
  /*#ul-1, #ul-2 {
    padding: 2px;
  }*/
  #ul-1 *, #ul-2 * {
  margin: 10px 3px;
  }
  .flex-ul {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .column2 {
    flex-direction: column;
    justify-content: flex-start;
  }
  #html-level, #css-level, #tailwind-level, #react-level, #express-level {
    width: 450px;
  }
   #technologies {
    margin-left: 0px;
    margin-bottom: 40px;
  }
  #ul-2 {
    margin: 10px auto;
  }
  #ul-1 {
    margin-right:   100px;
  } 
}
@media screen and (max-width:1024px) and (max-height:1368px){
  .nav2 {
    top: 90%;
  }
  #horizontal-line {
    top: 89.6%;
  }
  .layer {
    bottom: 5.2%;
  }
  .index-main {
    padding-bottom: 500px;
  }
}
@media screen and (max-width:768px){
  .layer {
    justify-content: center;
    padding: 0px 5px;
    width: 90%;
    bottom: 6.8%;
    height: 0vh;
    left: 5%;
  }
  .nav2 {
    top: 86.3%;
  }
  #horizontal-line {
    top: 85.9%;
  }
  .nav2 div {
    margin: 20px 10px;
    padding: 20px 30px;
    border-radius: 10px;
  }
}
@media screen and (max-width:645px) {
  .article-p { 
    max-width: 800px;
    min-width: 167px;
  }
  .nav2 {
    top: 80.5%;
  }
  #horizontal-line {
    top: 80.2%;
  }
}
@media screen and (max-width:630px){

  article.portfolio img {
    max-width: 80%;
    min-width: 80%;
    max-height: 30vh;
    min-height: 30vh;
    margin: 10px;
  }
  

  article.portfolio:hover .layer {
    height: 30.9vh;
  }
  .layer {
    justify-content: center;
    padding: 0px 5px;
    width: 80%;
    bottom: 14%;
    height: 0vh;
    left: 10%;
  }
  .layer h3{
    margin-bottom: 3px;
    font-weight: 500;
    font-size: 0.8rem;
    }
  .layer p {
    font-size: 0.5rem;
  }
  .layer a {
    padding: 5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-top: 5px;
  }
  .article-p { 
    font-size: 0.9rem;
    margin-left: 20px;
  }
}
@media screen and (max-width:625px) {
  
.theme-div {
  width: 25%;
}
.nav2 div {
  margin: 10px 2px;
  padding: 10px 5px;
  border-radius: 10px;
}
#theme-img {
  width: 7%;
}
  .nav2 div {
    margin: 20px 10px;
    padding: 20px 30px;
  }
  article.portfolio img {
    max-width: 80%;
    min-width: 80%;
    max-height: 30vh;
    min-height: 30vh;
    margin: 10px;
  }
  

  article.portfolio:hover .layer {
    height: 30.7vh;
  }
  .layer {
    justify-content: center;
    padding: 0px 5px;
    width: 80%;
    height: 0vh;
    left: 10%;
  }
  .layer h3{
    margin-bottom: 3px;
    font-weight: 500;
    font-size: 0.8rem;
    }
  .layer p {
    font-size: 0.5rem;
  }
  .layer a {
    padding: 5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-top: 5px;
  }
} @media screen and (max-width:590px) {

  .nav2 div {
    margin: 10px 2px;
    padding: 25px;
    border-radius: 10px;
  }
  }
@media screen and (max-width:571px) {
  
    .article-p { 
      font-size: 0.89rem;
      margin-left: 40px;
    }
}
@media screen and (max-width:545px) {
  
  .nav2 div {
    margin: 20px 5px;
    padding: 20px 5px;
  }
  #html-level, #css-level, #tailwind-level, #react-level, #express-level {
    width: 300px;
  }
  .theme-div {
    width: 150px;
  }
  .call-me {
    flex-direction: column;
  }
  .flex-contact {
    margin: 20px auto;
  }
  article.portfolio {
    max-width: 46%;
  }
  article.portfolio:hover .layer {
    height: 30.7vh;
  }
  
  article.portfolio img {
    max-width: 100%;
    min-width: 100%;
   }
  .layer {
    width: 100%;
    left: 0%;
  }
  main {
    padding-bottom: 160px;
  }
}
@media screen and (max-width:500px) {
  .article-p { 
    font-size: 0.8rem;
    margin-left: 40px;
    min-width: 150px;
  }
  
  .layer {
    bottom: 15%;
  }
}
@media screen and (max-width:453px) {
  .article-p { 
    font-size: 0.7rem;
    min-width: 130px;
  }
  .layer {
    bottom: 9.3%;
  }
  .nav2 {
    top: 84.8%;
  }
  #horizontal-line {
    top: 84.37%;
  }
}
@media screen and (max-width:410px) {
 
  #button-container {
    margin: 30px auto;
    display: flex;
    flex-direction: column;
  }

  #button-container * {
    margin: 10px auto;
  }
  .button-about {
    flex-direction: row;
  }
  #social-media-handles {
    margin-left: 22.5%;
  }
  #social-media-handles * {
    font-size: 2rem;
  }
  .article-p { 
    font-size: 0.6rem;
    min-width: 100px;
    margin-left: 15px  
  }
  .layer {
    bottom: 10%;
  }
  .nav2 {
    top: 83.8%;
  }
  #horizontal-line {
    top: 83.3%;
  }
}
@media screen and (max-width:375px) {
  
  .nav2 {
    top: 79%;
  }
  #horizontal-line {
    top: 78.37%;
  }
  .layer {
    top: 4%;
  }
}
@media screen and (max-width:370px) {
  
  .article-p { 
    font-size: 0.5rem;
    min-width: 80px;
    margin-left: 10px  
  }
  
  .nav2 {
    top: 82%;
  }
  #horizontal-line {
    top: 81.56%;
  }
}
@media screen and (max-width:350px) {
  
  .nav2 {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 21px;
    padding-bottom: 20px;
  }
  .nav2 div {
    margin: 10px 2px;
    padding: 10px 3px;
    border-radius: 10px;
  }
  .nav2 {
    top: 84%;
  }
  #horizontal-line {
    top: 83.6%;
  }
}
@media screen and (max-width:345px) {
 
  #social-media-handles {
    margin-left: 15% ;
  }
  #social-media-handles * {
    margin-right: 5px;
  }
  #profile-img {
    width: 80%;
  }
  .button {
    padding: 10px;
  }
  .about-flex div div {
    justify-content: flex-start;
  }
  /*#ul-1, #ul-2 {
    padding: 2px;
  }*/
  #ul-1 *, #ul-2 * {
  margin: 10px 3px;
  }
  .flex-ul {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .column2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  main#about-me-main {
    padding: 150px;
  }
  #html-level, #css-level, #tailwind-level, #react-level, #express-level {
    width: 150%;
  }
   #technologies {
    margin-left: 0px;
    margin-bottom: 40px;
  }
  #ul-2 {
    margin: 10px auto;
  }
  #ul-1 {
    margin-right:   100px;
  }
  article.portfolio {
    max-width: 30%;
    overflow: hidden;
  }
  .flex-contact {
    overflow: hidden;
  }
  .flex-contact i{
    font-size: 2rem;
  }
  .flex-contact p {
    font-size: 0.7rem;
    max-width: 110px;
  }
  .flex-contact {
    padding: 0px;
  } 
}

@media screen and (min-width:200px) and (max-height:700px) {

  .layer {
    bottom: 9.5%;
  }
}
@media screen and (min-width:200px) and (max-height:650px) {

  .layer {
    bottom: 10.2%;
  }
  .nav2 {
    top: 79%;
  }
  
  #horizontal-line {
    top: 78.4%;
  }
}
@media screen and (min-width:200px) and (max-height:600px) {

  .layer {
    bottom: 10.8%;
  }
  .nav2 {
    top: 77%;
  }
  
  #horizontal-line {
    top: 76.4%;
  }
}
@media screen and (min-width:200px) and (max-height:560px) {

  .layer {
    bottom: 11.2%;
  }
  .nav2 {
    top: 75%;
  }
  
  #horizontal-line {
    top: 74.4%;
  }
}

@media screen and (min-width:200px) and (max-height:500px) {

  .layer {
    bottom: 12.5%;
  }
  .nav2 {
    top: 72%;
  }
  
  #horizontal-line {
    top: 71.2%;
  }
}
@media screen and (min-width:200px) and (max-height:450px) {

  .layer {
    bottom: 13.7%;
  }
  .nav2 {
    top: 69%;
  }
  .theme-div {
    height: 15vh;
  }
  
  #horizontal-line {
    top: 68.4%;
  }
}
@media screen and (min-width:200px) and (max-height:400px) {

  .layer {
    bottom: 14.8%;
  }
  .nav2 {
    top: 64%;
  }
  
  #horizontal-line {
    top: 63.2%;
  }
}
@media screen and (min-width:200px) and (max-height:350px) {

  .layer {
    bottom: 16.5%;
  }
  .nav2 {
    top: 60%;
  }
  
  #horizontal-line {
    top: 59.2%;
  }
}
@media screen and (min-width:200px) and (max-height:300px) {

  .layer {
    bottom: 18.8%;
  }
  .nav2 {
    top: 53%;
  }
  .theme-div {
    height: 20vh;
  }
  
  #horizontal-line {
    top: 52.2%;
  }
}
@media screen and (min-height:1200px) {
  .index {
    height: 100vh;
  }
}
@media screen and (min-width:1280px) and (min-height:800px) {
  .index {
    height: 100vh;
  }
}